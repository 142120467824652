<template>
  <div class='boxAll'>
    <div>
      <div class="box">
        <div style="display: flex;">
          <div class="title ellipsis--l">{{workOrder.Title}}</div>
          <div class="status"
            v-if="workOrder.Status == 1">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
          <div class="status"
            v-if="workOrder.Status == 2"
            style="background-color:#F1586D;">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
          <div class="status"
            v-if="workOrder.Status == 3"
            style="background-color:#fcb00d;">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
          <div class="status"
            v-if="workOrder.Status == 4"
            style="background-color:#669bf9;">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
          <div class="status"
            v-if="workOrder.Status == 5"
            style="background-color:#2acbc2;">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
          <div class="status"
            v-if="workOrder.Status == 9"
            style="background-color:#ff3366;">
            <div style="color: white;">
              {{workOrder.StatusDesc}}
            </div>
          </div>
        </div>
        <div class="content">
          <div style="width: 50%;">
            创单人：{{workOrder.AddMan}}
          </div>
          <div>
            紧急程度：{{workOrder.UrgencyDesc}}
          </div>
        </div>
        <div class="content">
          <div style="width: 50%;">
            创单部门：{{workOrder.AddDeptName}}
          </div>
          <div>
            工单类型：{{workOrder.WOTypeDesc}}
          </div>

        </div>
        <div class="content">
          <div>
            系统名称：{{workOrder.SoftName}}
          </div>
        </div>
        <div class="content">
          <div style="width: 50%;">
            负责人：{{workOrder.DealMan}}
          </div>
          <div v-if="workOrder.DealDeptName != '' &&  workOrder.DealDeptName != null">
            负责部门：{{workOrder.DealDeptName}}
          </div>
        </div>
        <div class="content">
          <div>
            创单时间：{{workOrder.AddTime.replace('T', ' ') }}
          </div>
        </div>
        <van-divider />
        <div v-if="workOrder.Anndex !='' &&  workOrder.Anndex != null">
          <div class="title">附件</div>
          <div v-if="workOrder.Anndex != '' && workOrder.Anndex != null"
            style="display: flex;">
            <div>
              <div v-for="(anndex0 ,index) in workOrder.AnndexUrl.split(',')"
                :key="index+ 'aa'"
                style="margin: 8px 4px;font-size: 14px;"
                @click="downloadLoad(index)">
                <a :href="anndex0"
                  style="color:black;text-decoration:underline ;">{{anndex0.substring(anndex0.lastIndexOf("/") + 1)}}</a>

              </div>
            </div>
          </div>
          <van-divider />
        </div>
        <div v-if="workOrder.Content != '' && workOrder.Content != null">
          <div class="title ellipsis--l">内容</div>
          <div class="content"
            style="display: block;"
            v-html="workOrder.Content">
          </div>
        </div>
        <div v-if="workOrder.DealLog.length != 0 ">
          <van-divider />
          <div class="content">
            <van-steps direction="vertical">
              <van-step v-for="(item,index) in workOrder.DealLog"
                :key="index + 'cc'"
                style="line-height: 23px;">
                <div>
                  <span v-if="item.DealType == 1 ">接单</span>
                  <span v-if="item.DealType == 2 ">处理</span>
                  <span v-if="item.DealType == 3 ">驳回</span>
                  <span v-if="item.DealType == 4 ">完成</span>
                  <span v-if="item.DealType == 5 ">终止</span>时间： {{item.DealTime.replace('T', ' ') }}
                </div>
                <div>
                  <span style="margin-right: 10px;">
                    处理人：
                  </span>{{item.DealMan}}</div>
                <div>处理部门：{{item.DeptName}}</div>
                <div v-if=" item.DealSituation != '' && item.DealSituation != null ">处理情况：{{item.DealSituation}}</div>
                <div v-if=" item.Anndex != '' && item.Anndex != null "
                  style="display: flex;">
                  <div style="width: 70px;">
                    附件：
                  </div>
                  <div style="width: 70%;">
                    <div v-for="(anndex ,index) in item.AnndexUrl.split(',')"
                      :key="index + 'bb'"
                      style="margin-bottom:10px;text-decoration:underline ;"
                      @click="anndexClick(anndex)">
                      <!-- <a :href="anndex"
                        style="color:black;">{{anndex.substring(anndex.lastIndexOf("/") + 1)}}</a> -->
                      {{anndex.substring(anndex.lastIndexOf("/") + 1)}}
                    </div>
                  </div>
                </div>
              </van-step>
            </van-steps>
          </div>

        </div>
      </div>

      <!-- <div class="buttons">
        <van-button type="info"
          v-if="workOrder.Status == 1 "
          @click="postfrom()">编辑</van-button>
        <van-button type="primary"
          v-if="workOrder.Status == 1 "
          @click="suWorkOrder()">提报</van-button>
      </div> -->
      <div class="buttons"
        v-if="roles.roleTz == 2">
        <!-- <van-button type="primary"
          v-if="workOrder.Status == 2 "
          @click="recWorkOrder()">接收</van-button> -->

        <van-button type="warning"
          v-if="workOrder.Status == 2 && roles.stopWorkOrder == 1"
          @click="stopWorkOrder()">终止</van-button>
      </div>
      <div class="buttons"
        v-if="roles.roleTz == 2">
        <!-- <van-button type="primary"
          v-if="workOrder.Status == 3 "
          @click="eaWorkOrder()">处理</van-button> -->

        <van-button type="warning"
          v-if="workOrder.Status == 3  && roles.stopWorkOrder == 1"
          @click="stopWorkOrder()">终止</van-button>
        <!-- <van-button type="danger"
          v-if="workOrder.Status == 3 "
          @click="delWorkOrder()">删除</van-button> -->
      </div>
      <div class="buttons"
        v-if="roles.roleTz == 2">
        <van-button type="primary"
          v-if="workOrder.Status == 4  && roles.checkWorkOrder == 1"
          @click="checkWorkOrder()">验收</van-button>
        <van-button type="warning"
          v-if="workOrder.Status == 4  && roles.stopWorkOrder == 1"
          @click="stopWorkOrder()">终止</van-button>
      </div>
    </div>
    <!-- 处理工单 -->
    <div>
      <van-popup v-model="DealWorkOrderShow"
        @close="closeDealWorkOrder"
        position="bottom"
        round
        :style="{ height: '50%',background: '#f8f8f8', }"
        get-container="body">
        <h3 style="text-align:center;">
          处理工单
        </h3>
        <div style="margin:15px;">
          <div style="min-height: 34vh;">
            <div style="border-radiue:8px;">
              <van-field v-model="dealWorkOrderfrom.DealSituation"
                type="textarea"
                placeholder="请输入处理情况" />
            </div>
            <div style="margin:10px;">
              <van-uploader max-count="9"
                accept=".pdf,.docx.doc,.xlsx,txt"
                :after-read="handleAvatarSucces">
                <van-button plain
                  size="small"
                  type="info">上传附件</van-button>
              </van-uploader>
              <div v-if="Anndex.length != 0"
                style="color: #49a0fa">
                <div v-for="(item ,index) in Anndex"
                  :key="index+'aa'">
                  {{  item.substring(item.lastIndexOf("/") + 1)}}
                  <van-icon name="clear"
                    @click="deletAnndex(index)"
                    style="font-size: 18px;position: relative;top: 3px;"
                    color="#eb2672" />
                </div>
              </div>
            </div>
          </div>
          <div class="buttonSeal">
            <van-button type="primary"
              v-if="workOrder.Status == 3 "
              @click="sealWorkOrderSubmit()">保存</van-button>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 验收工单 -->
    <div>
      <van-popup v-model="CheckWorkOrderShow"
        @close="closeCheckWorkOrder"
        position="bottom"
        round
        :style="{ height: '60%',background: '#f8f8f8', }"
        get-container="body">
        <h3 style="text-align:center;">
          验收工单
        </h3>
        <div style="margin:15px;">
          <div style="min-height: 34vh;">
            <div style="border-radiue:8px;">
              <van-field v-model="checkWorkOrderfrom.Title"
                label="标题"
                type="textarea"
                rows="1"
                autosize
                placeholder="请输入标题" />
            </div>
            <div class="submit"
              style="padding:10px 15px;background-color: white;">
              <span style="color: #646566;font-size:14px;">验收结果</span>
              <div style="margin-left: 12%;display: inline-block;">
                <van-radio-group v-model="checkWorkOrderfrom.Result"
                  style="line-height:20px;"
                  direction="horizontal">
                  <van-radio name="1">通过</van-radio>
                  <van-radio name="2">退回</van-radio>
                  <van-radio name="3">终止</van-radio>
                </van-radio-group>
              </div>
            </div>
            <div style="border-radiue:8px;">
              <van-field v-model="checkWorkOrderfrom.DealSituation"
                label="验收情况"
                type="textarea"
                autosize
                placeholder="请输入验收情况" />
            </div>
            <div style="margin:10px;">
              <van-uploader max-count="9"
                accept=".pdf,.docx.doc,.xlsx,txt"
                :after-read="handleAvatarSucces">
                <van-button plain
                  size="small"
                  type="info">上传附件</van-button>
              </van-uploader>
              <div v-if="Anndex.length != 0"
                style="color: #49a0fa">
                <div v-for="(item ,index) in Anndex"
                  :key="index+'aa'">
                  {{  item.substring(item.lastIndexOf("/") + 1)}}
                  <van-icon name="clear"
                    @click="deletAnndex(index)"
                    style="font-size: 18px;position: relative;top: 3px;"
                    color="#eb2672" />
                </div>
              </div>
            </div>
          </div>
          <div class="buttonSeal">
            <van-button type="primary"
              v-if="workOrder.Status == 4 "
              @click="checkWorkOrderSubmit()">保存</van-button>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 终止工单 -->
    <div>
      <van-popup v-model="StopWorkOrderShow"
        @close="closeStopWorkOrder"
        position="bottom"
        round
        :style="{ height: '45%',background: '#f8f8f8', }"
        get-container="body">
        <h3 style="text-align:center;">
          终止工单
        </h3>
        <div style="margin:15px;">
          <div style="min-height: 28vh;">
            <div style="border-radiue:8px;">
              <van-field v-model="stopWorkOrderfrom.DealSituation"
                type="textarea"
                placeholder="请输入终止原因" />
            </div>
          </div>
          <div class="buttonSeal">
            <van-button type="primary"
              @click="stopWorkOrderSubmit()">保存</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
import config from "@/config";
import {
  GetWorkOrder,
  SubmitWorkOrder,
  RecWorkOrder,
  DealWorkOrder,
  CheckWorkOrder,
  StopWorkOrder,
  DeleteWorkOrder,
} from "@/api/aly";
import { getwgToken, setwgToken } from "@/utils/auth";
import { Dialog, Toast } from 'vant';
import axios from "axios";
export default {
  data () {
    return {
      workOrder: {},
      accToken: '',
      Anndex: [],
      url: config.apiUploadFiles,
      DealWorkOrderShow: false,
      CheckWorkOrderShow: false,
      StopWorkOrderShow: false,
      dealWorkOrderfrom: { //处理工单表单
        DealSituation: '',
        Anndex: '',
      },
      checkWorkOrderfrom: { //验收工单表单
        Title: '',
        Result: '',
        DealSituation: '',
        Anndex: '',
      },
      stopWorkOrderfrom: { //终止工单表单
        DealSituation: '',
      },
      roles: {},
    };
  },
  mounted () {
    if (this.$route.query.accToken != undefined && this.$route.query.accToken != null) {
      this.accToken = this.$route.query.accToken
      setwgToken(this.accToken);
    }
    this.getMyWorkOrderPage(this.$route.query.woid)
    this.roles = JSON.parse(this.$route.query.roles)
    console.log(this.roles);
  },
  methods: {
    // 获取工单
    getMyWorkOrderPage (val) {
      GetWorkOrder(
        {
          woId: val,
          accToken: this.accToken
        }
      ).then((res) => {
        if (res.data.code == 0) {
          this.workOrder = res.data.data
        }
      })
    },
    // 编辑
    postfrom () {
      this.$router.push({
        path: "/workOrderAdd/" + this.workOrder.WOId,
      });
    },
    // 提报
    suWorkOrder () {
      Dialog.confirm({
        title: '提示',
        message: '确认要提报工单吗',
      })
        .then(() => {
          SubmitWorkOrder({ WOId: this.workOrder.WOId, accToken: this.accToken }).then((res) => {
            if (res.data.code === 0) {
              Toast.success('提报成功');
              this.getMyWorkOrderPage(this.workOrder.WOId)
            } else {
              Toast.fail(res.data.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除工单
    delWorkOrder () {
      Dialog.confirm({
        title: '提示',
        message: '确认要删除工单吗',
      })
        .then(() => {
          DeleteWorkOrder({ WOId: this.workOrder.WOId, accToken: this.accToken }).then((res) => {
            if (res.data.code === 0) {
              Toast.success('删除成功');
              this.getMyWorkOrderPage(this.workOrder.WOId)
            } else {
              Toast.fail(res.data.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 接收
    recWorkOrder () {
      Dialog.confirm({
        title: '提示',
        message: '确认要接收工单吗',
      })
        .then(() => {
          RecWorkOrder({ WOId: this.workOrder.WOId, accToken: this.accToken }).then((res) => {
            if (res.data.code === 0) {
              Toast.success('接收成功');
              this.getMyWorkOrderPage(this.workOrde.WOId)
            } else {
              Toast.fail(res.data.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 处理
    eaWorkOrder () {
      this.Anndex = []
      this.DealWorkOrderShow = true;
    },
    // 关闭处理弹窗
    closeDealWorkOrder () {
      this.Anndex = []
      this.dealWorkOrderfrom = {
        DealSituation: '',
        Anndex: '',
      }
    },
    // 上传 相关
    handleAvatarSucces (file) {
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      let url = this.url;
      let formData = new FormData();
      formData.append('file', file.file);//必须是file.file，不然会报错
      //添加请求头
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      };
      axios
        .post(url, formData, config)
        .then((res) => {
          if (res.data.code == 0) {
            this.Anndex.push(res.data.data)
          }
        })
    },
    // 删除附件
    deletAnndex (val) {
      this.Anndex.splice(val, 1);
    },
    // 保存处理情况
    sealWorkOrderSubmit () {
      if (this.dealWorkOrderfrom.DealSituation == "") {
        Toast.fail("请输入处理情况");
        return false;
      }
      this.dealWorkOrderfrom.WOId = this.workOrder.WOId
      this.dealWorkOrderfrom.Anndex = this.Anndex + []
      this.dealWorkOrderfrom.accToken = this.accToken
      DealWorkOrder(this.dealWorkOrderfrom).then((res) => {
        if (res.data.code === 0) {
          Toast.success('处理成功');
          this.getMyWorkOrderPage(this.workOrder.WOId)
          this.DealWorkOrderShow = false;
          this.dealWorkOrderfrom = {
            DealSituation: '',
            Anndex: '',
          }
        } else {
          Toast.fail(res.data.data.msg);
        }
      });
    },
    // 验收弹窗
    checkWorkOrder () {
      this.Anndex = []
      this.CheckWorkOrderShow = true
    },
    // 关闭验收弹窗
    closeCheckWorkOrder () {
      this.Anndex = []
      this.checkWorkOrderfrom = {
        Title: '',
        Result: '',
        DealSituation: '',
        Anndex: '',
      }
    },
    // 保存验收情况
    checkWorkOrderSubmit () {
      if (this.checkWorkOrderfrom.Title == "") {
        Toast.fail("请输入标题");
        return false;
      }
      if (this.checkWorkOrderfrom.DealSituation == "") {
        Toast.fail("请选择验收结果");
        return false;
      }
      this.checkWorkOrderfrom.WOId = this.workOrder.WOId
      this.checkWorkOrderfrom.Anndex = this.Anndex + []
      this.checkWorkOrderfrom.accToken = this.accToken
      CheckWorkOrder(this.checkWorkOrderfrom).then((res) => {
        if (res.data.code === 0) {
          Toast.success('验收成功');
          this.getMyWorkOrderPage(this.workOrder.WOId)
          this.CheckWorkOrderShow = false;
          this.checkWorkOrderfrom = {
            Title: '',
            Result: '',
            DealSituation: '',
            Anndex: '',
          }
        } else {
          Toast.fail(res.data.data.msg);
        }
      });
    },
    // 终止工单
    stopWorkOrder () {
      this.StopWorkOrderShow = true
      this.stopWorkOrderfrom = { //终止工单表单
        DealSituation: '',
      }
    },
    // 关闭终止工单
    closeStopWorkOrder () {
      this.stopWorkOrderfrom = { //终止工单表单
        DealSituation: '',
      }
    },
    // 保存终止
    stopWorkOrderSubmit () {
      if (this.stopWorkOrderfrom.DealSituation == "") {
        Toast.fail("请输入终止原因");
        return false;
      }
      this.stopWorkOrderfrom.WOId = this.workOrder.WOId
      this.stopWorkOrderfrom.accToken = this.accToken
      StopWorkOrder(this.stopWorkOrderfrom).then((res) => {
        if (res.data.code === 0) {
          Toast.success('终止成功');
          this.getMyWorkOrderPage(this.workOrder.WOId)
          this.StopWorkOrderShow = false;
          this.stopWorkOrderfrom = {
            DealSituation: '',
          }
        } else {
          Toast.fail(res.data.data.msg);
        }
      });
    },
    // 点击下载
    anndexClick (val) {
      console.log(val);
      let a = document.createElement('a')
      a.href = val
      a.click();
    },
  }
}
</script>
<style  scoped>
.boxAll {
  min-height: 100%;
  background-color: #f8f8f8;
}
.box {
  margin: 10px 10px;
  background: white;
  padding: 15px;
  border-radius: 5px;
}
.title {
  width: 80%;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1b2024;
  line-height: 30px;
}
.status {
  width: 20%;
  text-align: right;
  background-color: #4b8cff;
  position: relative;
  top: -15px;
  right: -15px;
  border-top-right-radius: 8px;
  text-align: center;
  line-height: 31px;
}
.content {
  display: flex;
  margin: 8px 4px;
  font-size: 14px;
  /* color: #777A81; */
}
.buttons {
  text-align: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.buttons button {
  width: 80%;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}
.buttonSeal {
  width: 100%;
  text-align: center;
}
.buttonSeal button {
  width: 92%;
  padding: 10px;
  border-radius: 10px;
}
</style>